import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DeliveryNotAvailableAction} from '../../enums/DeliveryNotAvailableAction';
import {sleep} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-delivery-not-available-modal',
	templateUrl: './delivery-not-available-modal.component.html',
	styleUrls: ['delivery-not-available-modal.component.scss']
})
export class DeliveryNotAvailableModalComponent implements OnInit {
	action = DeliveryNotAvailableAction;

	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController): Promise<DeliveryNotAvailableAction | null> {
		const modal = await modalCtrl.create({
			component: DeliveryNotAvailableModalComponent,
			cssClass: 'delivery-not-available-modal',
			backdropDismiss: true,
			showBackdrop: true
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	async dismiss(action: DeliveryNotAvailableAction) {
		await this.modalCtrl.dismiss(action);
	}

	ngOnInit() {}
}
