import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const jamiesCustomerGroup = 'jamies';
export const customerGroup = jamiesCustomerGroup;

const supportEmail = {
	jamies: 'jamies@smoothr.de'
};
const firebaseConfig = {
	jamies: {
		apiKey: 'AIzaSyBx3rlbR5y81d9sWT4DJEfDlqFyqUmXaz0',
		authDomain: 'dev-env-216009.firebaseapp.com',
		databaseURL: 'https://dev-env-216009.firebaseio.com',
		projectId: 'dev-env-216009',
		storageBucket: 'dev-env-216009.appspot.com',
		messagingSenderId: '1004449889395',
		appId: '1:1004449889395:web:8b539d1f28f3b38be0fe06',
		measurementId: 'G-BFF40BZ926'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyASyhGnKtZa_Id2l-Gp9_vWIDDIIvlK2OE',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de'],
	gaId: '',
	hasRecommendations: false
};
