import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import ArticleCategory from '../../../smoothr-web-app-core/models/ArticleCategory';
import {sleep} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-category-chip-list',
	templateUrl: './category-chip-list.component.html',
	styleUrls: ['category-chip-list.component.scss']
})
export class CategoryChipListComponent implements OnInit {
	@Input()
	categories: ArticleCategory[] = [];
	@Output()
	categorySelected = new EventEmitter<number>();
	@ViewChild('categoryList', {static: false}) categoryList;

	constructor() {}

	private _selectedCategoryIndex = 0;

	get selectedCategoryIndex(): number {
		return this._selectedCategoryIndex;
	}

	@Input()
	set selectedCategoryIndex(value: number) {
		if (this._selectedCategoryIndex === value) {
			return;
		}
		this._selectedCategoryIndex = value;
		this.scrollCategoryListTo(value);
	}

	ngOnInit() {}

	select(index: number) {
		this.categorySelected.emit(index);
	}

	private async scrollCategoryListTo(index: number) {
		if (
			index < 0 ||
			!this.categoryList?.nativeElement?.children[index] ||
			(!this.categoryList?.nativeElement?.scrollTo && !this.categoryList?.nativeElement?.scrollLeft)
		) {
			return;
		}
		this.categoryList.nativeElement.scrollTo(this.categoryList.nativeElement.children[index].offsetLeft - 25, 0);
		await sleep(100);
	}
}
